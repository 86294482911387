


























import Vue from 'vue';
import Loading from '@/blueprint/components/ui/Loading.vue';
import TenancyCard from '@/blueprint/components/landlord/tenancy/Tenancy-Card.vue';
import CreateTenancy from '@/blueprint/components/landlord/tenancy/Create-Tenancy.vue';

import gql from 'graphql-tag';
import { GQLTagRequestObject } from '@/assets/clients/gqlClient';
import { Property } from '@lordly/models2/interfaces/models/Property';
import { Tenancy } from '@lordly/models2/interfaces/models/Tenancy';
import { API } from '@lordly/models2/interfaces/gql';

export default Vue.extend({
  name: 'Tenancy-List-Page',
  components: {
    'loading': Loading,
    'tenancy-card': TenancyCard,
    'create-tenancy': CreateTenancy,
  },
  data () {
    return {
      loadingTenancies: false,
      property: {} as Partial<Property>,
      tenancies: [] as Array<Partial<Tenancy>>,
      id: '',
      partition: '',
    };
  },
  created () {
    this.id = this.$route.params.id;
    this.partition = this.$route.params.city;
    // Get tenancies
    this.getTenancies();
  },
  methods: {
    async getTenancies () {
      // Set loading
      this.loadingTenancies = true;
      // Create query
      const query: GQLTagRequestObject = gql`
        query ($id: String!, $partition: String!) {
          GetProperty (
            input: {
              id: $id,
              partition: $partition
            }
          ) {
            dates {
              tenancyStart
              tenancyEnd
            }
            __typename
            ... on PropertyExtended {
              tenancies {
                id
                limit
                tenancy {
                  start
                  end
                }
              }
            }
          }
        }
      `;
      // Create query variables
      const payload: Partial<API.GetPropertyInput> = {
        id: this.id,
        partition: this.partition,
      };
      try {
        const response: Partial<Property> = await this.$gql.Query('GetProperty', query, payload);
        // Set Property
        this.property['dates'] = response.dates;
        // Set tenancies
        this.tenancies = response.tenancies as unknown as Array<Partial<Tenancy>>;
        // Map properties as keyvalue pair
        this.tenancies.map((t) => {
          t['property'] = {
            id: this.id,
            partition: this.partition,
          };
        });
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingTenancies = false;
      }
    },
    addNewTenancy (tenancy: Partial<Tenancy>) {
      this.tenancies.push(tenancy);
    },
  },
});
