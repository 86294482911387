



























































import Vue from 'vue';
import LDatePicker from '@/blueprint/components/ui/DatePicker.vue';
import Loading from '@/blueprint/components/ui/Loading.vue';
import CloseBtn from '@/blueprint/components/ui/CloseButton.vue';
import { ValidateFields } from '@/assets/mixins';

import gql from 'graphql-tag';
import { GQLTagRequestObject } from '@/assets/clients/gqlClient';
import { Property } from '@lordly/models2/interfaces/models/Property';
import { Tenancy } from '@lordly/models2/interfaces/models/Tenancy';
import { API } from '@lordly/models2/interfaces/gql';
import { ActionPayload } from '@/interfaces';

export default Vue.extend({
  name: 'Tenancy-Create-Component',
  components: {
    'ldate-picker': LDatePicker,
    'loading': Loading,
    'close-btn': CloseBtn,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
      required: true,
    },
    propertyId: {
      type: String,
      default: '',
      required: true,
    },
    propertyPartition: {
      type: String,
      default: '',
      required: true,
    },
    property: {
      type: Object as () => Partial<Property>,
      default: () => {
        return {
          dates: {
            tenancyStart: new Date().toISOString(),
            tenancyEnd: new Date().toISOString(),
          },
        };
      },
      required: true,
    },
  },
  data: () => {
    return {
      showForm: false,
      form: {
        tenancyStart: '',
        tenancyEnd: '',
      },
      creatingTenancy: false,
    };
  },
  computed: {
    defaultTenancyStart (): string {
      return this.getDefaultDate(this.property.dates!.tenancyStart as string);
    },
    defaultTenancyEnd (): string {
      return this.getDefaultDate(this.property.dates!.tenancyEnd as string);
    },
  },
  methods: {
    getDefaultDate (date: string): string {
      const tenancyDate: Date = new Date(date);
      if (typeof tenancyDate === 'object') {
        return this.generateDefaultDate(date);
      } else {
        return this.generateDefaultDate();
      }
    },
    generateDefaultDate (date: string = '') {
      let targetDate: Date = date ? new Date(date) : new Date();
      return String(targetDate.getFullYear() + '/' + (0 + String(targetDate.getMonth() + 1)).slice(-2));
    },
    validateFields (inputs: string[]) {
      return ValidateFields(this, inputs, true);
    },
    async createTenacy () {
      let hasError: boolean = !this.validateFields(['tenancyStart', 'tenancyEnd']);
      // If no errors
      if (!hasError) {
        // Create query
        const query: GQLTagRequestObject = gql`
          mutation ($id: String!, $partition: String!, $startDate: String!, $endDate: String!) {
            CreateTenancy (
              input: {
                id: $id,
                partition: $partition,
                startDate: $startDate,
                endDate: $endDate
              }
            ) {
              id
              limit
              tenancy {
                start
                end
              }
            }
          }
        `;
        // Parse tenancy dates to ISO string
        const isoTenancyStartDate: string = new Date(this.form.tenancyStart).toISOString();
        const isoTenancyEndDate: string = new Date(this.form.tenancyEnd).toISOString();
        // Create query payload
        const payload: API.CreateTenancyInput = {
          id: this.propertyId,
          partition: this.propertyPartition,
          startDate: isoTenancyStartDate,
          endDate: isoTenancyEndDate,
        };
        // Set loading
        this.creatingTenancy = true;
        // Send query
        try {
          const response: Partial<Tenancy> = await this.$gql.Mutation('CreateTenancy', query, payload);
          // BugFix: Add property Id/partition attribute so that it can be navigateable
          response.property = {
            id: this.propertyId,
            partition: this.propertyPartition,
          };
          this.$emit('tenancy', response);
          this.showForm = false;
          // BugFix - Refresh portfolio async so that tenancies count is correct in the background
          this.$store.dispatch('landlord/actionGetPortfolio', { component: this } as ActionPayload);
        } catch (e) {
          console.error(e);
        } finally {
          this.creatingTenancy = false;
        }
      }
    },
  },
});
