

















import Vue from 'vue';
import { Tenancy } from '@lordly/models2/interfaces/models/Tenancy';

export default Vue.extend({
  name: 'Tenancy-Card-Component',
  props: {
    tenancy: {
      type: Object as () => Partial<Tenancy>,
      default: () => {
        return {
          id: '',
          property: {
            id: '',
            partition: '',
          },
          tenancy: {
            start: '',
            end: '',
          },
          limit: 0,
        };
      },
      required: true,
    },
  },
  computed: {
    fullYearStart (): number {
      return new Date(this.tenancy.tenancy!.start).getFullYear();
    },
    fullYearEnd (): number {
      return new Date(this.tenancy.tenancy!.end).getFullYear();
    },
    currentTenancy (): boolean {
      let payload: boolean = false;
      if (new Date(this.tenancy.tenancy!.end) >= new Date() && new Date(this.tenancy.tenancy!.start) <= new Date()) {
        payload = true;
      }
      return payload;
    },
    upcomingTenancy () {
      let payload: boolean = false;
      if (new Date(this.tenancy.tenancy!.end) >= new Date() && new Date(this.tenancy.tenancy!.start) > new Date()) {
        payload = true;
      }
      return payload;
    },
    tenancyLimitWarningColor () {
      if (this.tenancy.limit! > -1 && this.tenancy.limit! < 80) {
        return 'green';
      } else if (this.tenancy.limit! >= 80 && this.tenancy.limit! < 95) {
        return 'orange';
      } else if (this.tenancy.limit! >= 95) {
        return 'red';
      } else {
        return 'grey';
      }
    },
  },
  methods: {
    showTenancy () {
      const routePath: string = '/landlord/portfolio/tenancy/' + this.tenancy.id + '/' + this.tenancy.property!.partition;
      this.$router.push(routePath);
    },
  },
});
